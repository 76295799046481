import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import elyLogo from '../assets/images/ely_logo.png';

gsap.registerPlugin(ScrollTrigger);

const HeroStyles = styled.section`
  background: #fecb47;
  /* min-height: calc(100vh - (7rem + var(--margin))); */
  display: flex;
  align-items: flex-start;
  /* justify-content: center; */
  flex-direction: column;
  img {
    margin: calc((var(--margin)) / 2);
    /* max-width: 20rem; */
    width: min(40vw, 20rem);
    /* align-self: flex-start; */
  }
  h1 {
    align-self: center;
    margin-bottom: calc((var(--margin)) * 2);
    margin-right: calc((var(--margin)) * 1);
    margin-bottom: calc((var(--margin)) * 2);
    margin-left: calc((var(--margin)) * 1);
    @media (max-width: 1024px) {
      /* margin-bottom: calc((var(--margin)) * 2); */
    }
    @media (max-width: 768px) {
      margin-top: calc((var(--margin)) * 4);
      margin-bottom: calc((var(--margin)) * 3);
    }
  }
  span {
    color: var(--white);
  }
  @media (max-width: 1024px) {
    /* min-height: calc(100vh - (7rem + var(--margin))); */
    min-height: 80vh;
  }
`;

export default function Hero({ children }) {
  const headerRef = useRef(null);

  useEffect(() => {
    gsap.from(headerRef.current, {
      y: '-100px',
      autoAlpha: 0.3,
      duration: 1,
    });
  }, []);

  return (
    <HeroStyles>
      <img src={elyLogo} alt="ELY-keskuksen logo" />
      <h1 ref={headerRef}>{children}</h1>
    </HeroStyles>
  );
}
