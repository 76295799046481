import styled from "styled-components";

const PageStyles = styled.main`
  margin-right: var(--margin);
  margin-bottom: var(--margin);
  margin-left: var(--margin);
  display: grid;
  row-gap: calc((var(--row-gap)) * 2);
  .bar {
    height: 7px;
    /* max-width: 300px; */
    display: block;
    background: var(--yellow);
  }
  .indent {
    margin-left: 25%;
  }
  .text-link {
    display: inline-block;
        transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
        :hover {
          transform: scale(1.1);
          transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
          color: var(--yellow);
        }
  }
`;

export default PageStyles;
